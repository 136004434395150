import "./App.css";
import "./assets/fonts/DINR____.TTF";
import "./assets/fonts/DINB____.TTF";
import "./assets/fonts/DIN Medium.ttf";
import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { useSessionStorage } from "usehooks-ts";
import { HelmetProvider } from "react-helmet-async";
import CMSClient from "./api/CMS-API";
import Layout from "./Layout";
import Landing from "./pages/Landing";
import Work from "./pages/Work";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Blog from "./pages/Blog";
import Invalid from "./pages/Invalid";
import ReactGA from "react-ga4";
import JumpToTop from "./components/JumpToTop";
import ImagePreloader from "./components/AboutImagePreloader";
import AppLoading from "./components/AppLoading";
import PMProject from "./pages/PM-Project";
import PMWork from "./pages/PM-Work";

const GAKey = process.env.REACT_APP_GA_TRACKINGID;

ReactGA.initialize(GAKey);

export default function App() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [showLoader, setShowLoader] = useState(false);
    const [appLoaded, setAppLoaded] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [showJumpButton, setShowJumpButton] = useState(false);
    const [typingAnimationData, setTypingAnimationData] = useState({});
    const [aboutPageData, setAboutPageData] = useState({});
    const [resumeData, setResumeData] = useState({});
    const [contactPageData, setContactPageData] = useState({});
    const [workPageData, setWorkPageData] = useState({});
    const [blogPageData, setBlogPageData] = useState({});
    const [pMCaseStudyData, setPMCaseStudyData] = useSessionStorage(
        "pMCaseStudyData",
        {}
    );
    const [currentPMProject, setCurrentPMProject] = useSessionStorage(
        "currentPMProject",
        ""
    );

    useEffect(() => {
        setShowLoader(true);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const query = `
            {
                "typingAnimationData": *[_type == "landingTypingText"][0]{typingSpeed, typingDelay, eraseSpeed, eraseDelay, typingPhrases},
                "contactInfo": *[_type == "contactInfo"][0]{pageHeader, phoneNo, pageBody, pageSubHeader, gitHubLink, gitLabLink, linkedInLink},
                "aboutInfo": *[_type == "aboutInfo"][0]{"avatarImageURL": avatarImage.asset->url, "avatarImageAlt": avatarImage.altText, aboutPageTitle, aboutPageSubtitle, aboutBody},
                "resumeData": *[_type == "resumeData"][0]{workExperience, developmentExperience, education, technicalSkills, "resumePDF": resumePDF.asset->url},
                "workData": *[_type == "work"][0] {
                    pageHeader,
                    pageSubHeader,
                    projects[] {
                        _key, // Include the _key field for each iterable
                        projectTitle,
                        projectSubTitle,
                        projectDescription,
                        projectRepository {
                            repositoryLinkName,
                            repositoryLinkURL
                        },
                        projectDeployment {
                            deploymentLinkName,
                            deploymentLinkURL
                        },
                        visual {
                            visualType,
                            portraitVideoUrl,
                            portraitVideoTitle,
                            landscapeVideoUrl,
                            landscapeVideoTitle,
                            "photoURL": photo.asset->url,
                            photoAltText
                        }
                    }
                },
                "blogData": *[_type == "blog"][0] {
                    pageHeader,
                    pageSubHeader,
                    posts[] {
                        _key,
                        postTitle,
                        postDate,
                        "photoURL": photo.asset->url,
                        photoAltText,
                        photoCaption,
                        postBody[]
                    }
                },
                "caseStudyData": *[_type == "pmCaseStudies"][0] {
                    pageHeader,
                    pageSubHeader,
                    caseStudies[] {
                        _key,
                        caseStudyTitle,
                        caseStudyCompany,
                        caseStudySummary,
                        "caseStudyHeroPhotoURL": heroPhoto.asset->url,
                        "caseStudyHeroPhotoAlt": heroPhoto.altText,
                        "caseStudyHeroPhotoCaption": heroPhoto.caption,
                        story,
                        product,
                        success,
                        calloutBox,
                        photos[] {
                            "caseStudyPhotoURL": asset->url,
                            caption,
                            altText
                        }
                    }
                }
            }
        `;
            try {
                const result = await CMSClient.fetch(query);
                setTypingAnimationData(result.typingAnimationData);
                setContactPageData(result.contactInfo);
                setAboutPageData(result.aboutInfo);
                setResumeData(result.resumeData);
                setPMCaseStudyData(result.caseStudyData);
                setWorkPageData(result.workData);
                setBlogPageData(result.blogData);
                setTimeout(() => {
                    setShowLoader(false);
                    setAppLoaded(true);
                }, "1500");
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const location = useLocation();

    const handleScroll = () => {
        const scrollTop =
            window.pageYOffset || document.documentElement.scrollTop;
        setShowJumpButton(location.pathname !== "/" && scrollTop > 0);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [location]);

    return (
        <>
            <div className={`App fade-in ${appLoaded ? "active" : ""}`}>
                <HelmetProvider>
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <Layout
                                    windowWidth={windowWidth}
                                    drawerOpen={drawerOpen}
                                    setDrawerOpen={setDrawerOpen}
                                />
                            }
                        >
                            <Route
                                index
                                element={
                                    <Landing
                                        windowWidth={windowWidth}
                                        typingAnimationData={
                                            typingAnimationData
                                        }
                                    />
                                }
                            />
                            <Route
                                path="work/"
                                element={<Work workPageData={workPageData} />}
                            />
                            <Route
                                path="pmcasestudies/"
                                element={
                                    <PMWork
                                        pMCaseStudyData={pMCaseStudyData}
                                        currentPMProject={currentPMProject}
                                        setCurrentPMProject={
                                            setCurrentPMProject
                                        }
                                    />
                                }
                            />
                            <Route
                                path="pmcasestudies/projectdetail/"
                                element={
                                    <PMProject
                                        pMCaseStudyData={pMCaseStudyData}
                                        currentPMProject={currentPMProject}
                                        setCurrentPMProject={
                                            setCurrentPMProject
                                        }
                                    />
                                }
                            />
                            <Route
                                path="about/"
                                element={
                                    <About
                                        windowWidth={windowWidth}
                                        setDrawerOpen={setDrawerOpen}
                                        aboutPageData={aboutPageData}
                                        resumeData={resumeData}
                                    />
                                }
                            />
                            <Route
                                path="contact/"
                                element={
                                    <Contact
                                        contactPageData={contactPageData}
                                    />
                                }
                            />
                            <Route
                                path="blog/"
                                element={<Blog blogPageData={blogPageData} />}
                            />
                            <Route path="*" element={<Invalid />} />
                        </Route>
                    </Routes>
                </HelmetProvider>
                <JumpToTop
                    showJumpButton={showJumpButton}
                    drawerOpen={drawerOpen}
                />
                <ImagePreloader />
            </div>
            <div className={`fade-in ${showLoader ? "active" : ""}`}>
                <AppLoading />
            </div>
        </>
    );
}
