import CircularProgress from "@mui/material/CircularProgress";

export default function AppLoading() {
    const customColorStyle = {
        color: "#FFE443",
    };

    return (
        <div className="Homepage-Loading-Container">
            <CircularProgress size={80} style={customColorStyle} />
        </div>
    );
}
