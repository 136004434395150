import StyledIconButton from "./CustomIconButton";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

export default function ContactLinkedInIcon(props) {
    return (
        <StyledIconButton
            href={props.props.contactPageData.linkedInLink}
            target="blank"
        >
            <LinkedInIcon className="Contact-Icons" />
        </StyledIconButton>
    );
}
