import Carousel from "react-bootstrap/Carousel";

const imageStyle = {
    display: "block",
    margin: "auto", // Center horizontally
    marginTop: "6rem", // Add margin to the top
    marginBottom: "8rem", // Add margin to the bottom
    maxHeight: "500px",
    maxWidth: "100%"
};

export default function ProjectImageGallery({ carouselItems }) {
    return (
        carouselItems && (
            <>
                <Carousel interval={null}>
                    {carouselItems.map((item, index) => (
                        <Carousel.Item key={index}>
                            <img
                                alt={item.altText}
                                src={item.caseStudyPhotoURL}
                                style={imageStyle}
                            />
                            <Carousel.Caption>
                                <p>{item.caption}</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </>
        )
    );
}
