import { useEffect } from "react";
import ReactGA from "react-ga4";
import SEO from "../api/SEO";

const PostImage = ({ postImageAlt, postImageURL, postImageCaption }) => {
    return (
        <>
            <img
                className="Blog-Post-Image"
                alt={postImageAlt}
                src={postImageURL}
                loading="lazy"
            />
            {postImageCaption && (
                <p className="Blog-Image-Caption-Text">{postImageCaption}</p>
            )}
        </>
    );
};

const BlogBodyBlock = ({ postBody }) => {
    const renderBlocks = (blocks) => {
        return blocks.map((block, index) => {
            if (block._type === "block" && block.children) {
                return (
                    <p key={index}>
                        {block.children.map((child, childIndex) => {
                            if (child._type === "span") {
                                return (
                                    <span
                                        className="Blog-Body-Text"
                                        key={childIndex}
                                    >
                                        {child.text}
                                    </span>
                                );
                            }
                            return null;
                        })}
                    </p>
                );
            }
            return null;
        });
    };
    return <div>{renderBlocks(postBody)}</div>;
};

const BlogPost = ({
    postTitle,
    postDate,
    postImageFile,
    postImageAltText,
    postImageDescription,
    postBody,
    isLastPost,
}) => {
    return (
        <>
            <div className="Page-Content-Column-4">
                {postTitle && (
                    <div className="H3-Header">
                        <h3>{postTitle}</h3>
                    </div>
                )}
                {postDate && (
                    <div className="Body-Text-Bold">
                        <p>{postDate}</p>
                    </div>
                )}
                <br />
                {postImageFile && (
                    <PostImage
                        postImageAlt={postImageAltText}
                        postImageCaption={postImageDescription}
                        postImageURL={postImageFile}
                    />
                )}
                <br />
                {postBody && <BlogBodyBlock postBody={postBody} />}
            </div>
            {!isLastPost && <div className="Work-Horizontal-Section-Divider" />}
        </>
    );
};

export default function Blog(props) {
    const pageHeader = props.blogPageData.pageHeader;
    const pageSubHeader = props.blogPageData.pageSubHeader;
    const posts = props.blogPageData.posts;

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "blog/" });
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <SEO
                title="Blog"
                description="Jeff's Blog"
                name="Jeff Pettit"
                type="website"
            />
            <div className="Page-Background-Container">
                <div className="Page-Content-Container-1">
                    {pageHeader && (
                        <div className="H1-Header">
                            <h1>{pageHeader}</h1>
                        </div>
                    )}
                    {pageSubHeader && (
                        <>
                            <br />
                            <div className="H3-Header">
                                <h3>{pageSubHeader}</h3>
                            </div>
                        </>
                    )}

                    <div className="Work-Horizontal-Section-Divider" />

                    {posts &&
                        posts.map((post, index) => (
                            <div key={index}>
                                <BlogPost
                                    postTitle={post.postTitle}
                                    postDate={post.postDate}
                                    postImageFile={post.photoURL}
                                    postImageAltText={post.photoAltText}
                                    postImageDescription={post.photoCaption}
                                    postBody={post.postBody}
                                    isLastPost={index === posts.length - 1}
                                />
                            </div>
                        ))}
                </div>
            </div>
        </>
    );
}
