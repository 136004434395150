import ProjectImageGallery from "../components/ProjectImageGallery";
import GoBackButtonPM from "../components/GoBackButton-PMDetail";
import { useState, useEffect } from "react";
import CalloutBox from "../components/PMCalloutBoxText";
import { useSessionStorage } from 'usehooks-ts'
import ReactGA from "react-ga4";

const ParagraphBlock = ({ text }) => {
    const renderBlocks = (blocks) => {
        return blocks.map((block, index) => {
            if (block._type === "block" && block.children) {
                return (
                    <p key={index}>
                        {block.children.map((child, childIndex) => {
                            if (child._type === "span") {
                                return (
                                    <span key={childIndex}>{child.text}</span>
                                );
                            }
                            return null;
                        })}
                    </p>
                );
            }
            return null;
        });
    };
    return <>{renderBlocks(text)}</>;
};

export default function PMProject(props) {
    const [projectLoaded, setProjectLoaded] = useState(false);
    const [currentPageProject, setCurrentPageProject] = useSessionStorage("currentPageProject");

    const caseStudies = props.pMCaseStudyData.caseStudies;

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "pmcasestudies/projectdetail/" });
    }, []);

    useEffect(() => {
        for (const caseStudy of caseStudies) {
            if (caseStudy._key === props.currentPMProject) {
                setCurrentPageProject(caseStudy);
            }
        }
        if (currentPageProject !== null) {
            setProjectLoaded(true);
        }
    }, []);

    const setCurrentPMProject = props.setCurrentPMProject;
    return (
        projectLoaded && (
            <div className="Page-Background-Container">
                <div className="Page-Content-Container-1">
                    {currentPageProject.caseStudyTitle && (
                        <div className="H1-Header">
                            <h1>{currentPageProject.caseStudyTitle}</h1>
                        </div>
                    )}
                    {currentPageProject.caseStudySummary && (
                        <>
                            <br />
                            <div className="H3-Header">
                                <h3>
                                    <ParagraphBlock
                                        text={
                                            currentPageProject.caseStudySummary
                                        }
                                    />
                                </h3>
                            </div>
                        </>
                    )}
                    <br />
                    <ProjectImageGallery
                        carouselItems={currentPageProject.photos}
                    />
                    <br />
                    <div className="PM-Detail-Body-Container">
                        <div className="PM-Detail-Main-Column">
                            {currentPageProject.product && (
                                <>
                                    <div className="H4-Header">
                                        <h4>The Product</h4>
                                    </div>
                                    <div className="Body-Text">
                                        <p>
                                            <ParagraphBlock
                                                text={
                                                    currentPageProject.product
                                                }
                                            />
                                        </p>
                                    </div>
                                </>
                            )}
                            {currentPageProject.story && (
                                <>
                                    <br />
                                    <div className="H4-Header">
                                        <h4>Story</h4>
                                    </div>
                                    <div className="Body-Text">
                                        <p>
                                            <ParagraphBlock
                                                text={currentPageProject.story}
                                            />
                                        </p>
                                    </div>
                                </>
                            )}
                            {currentPageProject.success && (
                                <>
                                    <br />
                                    <div className="H4-Header">
                                        <h4>Success</h4>
                                    </div>
                                    <div className="Body-Text">
                                        <p>
                                            <ParagraphBlock
                                                text={
                                                    currentPageProject.success
                                                }
                                            />
                                        </p>
                                    </div>
                                </>
                            )}
                            <br />
                        </div>
                        {currentPageProject.calloutBox && (
                            <div className="PM-Detail-Secondary-Column">
                                <div className="Body-Text">
                                    <CalloutBox
                                        blocks={currentPageProject.calloutBox}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <br />
                    <div>
                        <GoBackButtonPM
                            setCurrentPMProject={setCurrentPMProject}
                        />
                    </div>
                </div>
            </div>
        )
    );
}
