import { useNavigate } from "react-router";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import SEO from "../api/SEO";

const ProjectImage = ({ projectImageAlt, projectImageURL }) => {
    return (
        <img
            className="PM-Work-Project-Image"
            alt={projectImageAlt}
            src={projectImageURL}
            loading="lazy"
        />
    );
};

const ProjectDescriptionBlock = ({ projectDescription }) => {
    const renderBlocks = (blocks) => {
        return blocks.map((block, index) => {
            if (block._type === "block" && block.children) {
                return (
                    <p key={index}>
                        {block.children.map((child, childIndex) => {
                            if (child._type === "span") {
                                return (
                                    <span key={childIndex}>{child.text}</span>
                                );
                            }
                            return null;
                        })}
                    </p>
                );
            }
            return null;
        });
    };
    return <div>{renderBlocks(projectDescription)}</div>;
};

const Project = ({
    projectID,
    setCurrentPMProject,
    projectHeroImageURL,
    projectHeroImageAlt,
    projectName,
    projectCompany,
    projectDescription,
    isLastProject,
}) => {
    const navigate = useNavigate();

    const handleProjectClick = () => {
        setCurrentPMProject(projectID);
        navigate("/pmcasestudies/projectdetail");
    };

    return (
        <>
            <div className="Page-Content-Container-2">
                {projectHeroImageURL && (
                    <div className="Page-Content-Column-3">
                        <div
                            className="PM-Project-Image-Link"
                            onClick={handleProjectClick}
                        >
                            <ProjectImage
                                projectImageAlt={projectHeroImageAlt}
                                projectImageURL={projectHeroImageURL}
                            />
                        </div>
                    </div>
                )}
                <div className="Page-Content-Column-4">
                    {projectName && (
                        <div
                            className="PM-Project-Title-Link-Container"
                            onClick={handleProjectClick}
                        >
                            <div className="PM-Project-Title-Link">
                                <h2>{projectName}</h2>
                            </div>
                        </div>
                    )}
                    {projectCompany && (
                        <div className="H4-Header">
                            <h4>{projectCompany}</h4>
                        </div>
                    )}
                    <div className="Body-Text">
                        {projectDescription && (
                            <ProjectDescriptionBlock
                                projectDescription={projectDescription}
                            />
                        )}
                    </div>
                </div>
            </div>
            {!isLastProject && (
                <div className="Work-Horizontal-Section-Divider" />
            )}
        </>
    );
};

export default function PMWork(props) {
    const pageTitle = props.pMCaseStudyData.pageHeader;
    const pageSubTitle = props.pMCaseStudyData.pageSubHeader;
    const caseStudies = props.pMCaseStudyData.caseStudies;

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "pmcasestudies/" });
    }, []);

    return (
        <>
            <SEO
                title="Product Management Case Studies"
                description="Explore Jeff's work as a Product Manager"
                name="Jeff Pettit"
                type="website"
            />
            <div className="Page-Background-Container">
                <div className="Page-Content-Container-1">
                    {pageTitle && (
                        <div className="H1-Header">
                            <h1>{pageTitle}</h1>
                        </div>
                    )}
                    {pageSubTitle && (
                        <>
                            <br />
                            <div className="H3-Header">
                                <h3>{pageSubTitle}</h3>
                            </div>
                        </>
                    )}

                    <div className="Work-Horizontal-Section-Divider" />

                    {caseStudies &&
                        caseStudies.map((caseStudy, index) => (
                            <div key={index}>
                                <Project
                                    projectID={caseStudy._key}
                                    setCurrentPMProject={
                                        props.setCurrentPMProject
                                    }
                                    projectHeroImageURL={
                                        caseStudy.caseStudyHeroPhotoURL
                                    }
                                    projectHeroImageAlt={
                                        caseStudy.caseStudyHeroPhotoAlt
                                    }
                                    projectName={caseStudy.caseStudyTitle}
                                    projectCompany={caseStudy.caseStudyCompany}
                                    projectDescription={
                                        caseStudy.caseStudySummary
                                    }
                                    isLastProject={
                                        index === caseStudies.length - 1
                                    }
                                />
                            </div>
                        ))}
                </div>
            </div>
        </>
    );
}
