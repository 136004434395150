import { useEffect } from "react";
import HomepageTyping from "../components/TypingAnimation";
import ReactGA from "react-ga4";
import SEO from "../api/SEO";

export default function Landing(props) {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/" });
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <SEO
                title="Jeff Pettit"
                description="Jeff Pettit is an experienced product management, design, and engineering leader with 8 years shaping consumer-facing product development, production roadmaps, and managing multi-vendor supply chains."
                name="Jeff Pettit"
                type="website"
            />
            <div className="Homepage-Parent-Container">
                {props.windowWidth >= 700 ? (
                    <div className="Homepage-Container">
                        <div>
                            <h1 className="Homepage-Intro-Text">Hi,</h1>
                            <h1 className="Homepage-Intro-Text">I'm Jeff.</h1>
                        </div>
                        <div className="Vertical-Line-1" />
                        <div className="Vertical-Line-2" />
                        <div className="Typing-Container">
                            <h1 className="Homepage-About-Text">I'm</h1>
                            <HomepageTyping props={props} />
                        </div>
                    </div>
                ) : (
                    <div className="Homepage-Container">
                        <div>
                            <h1 className="Homepage-Intro-Text">
                                Hi, I'm Jeff.
                            </h1>
                        </div>
                        <div className="Typing-Container">
                            <h1 className="Homepage-About-Text">
                                I'm <HomepageTyping props={props} />
                            </h1>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
