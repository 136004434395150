import ReactTypingEffect from "react-typing-effect";

export default function HomepageTyping(props) {
    return (
        <>
            <ReactTypingEffect
                className="Homepage-About-Text-Animated"
                text={props.props.typingAnimationData.typingPhrases}
                speed={props.props.typingAnimationData.typingSpeed}
                eraseSpeed={props.props.typingAnimationData.eraseSpeed}
                eraseDelay={props.props.typingAnimationData.eraseDelay}
                typingDelay={props.props.typingAnimationData.typingDelay}
            />
        </>
    );
}
