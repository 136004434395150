import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router";

const YellowButton = styled(Button)({
    fontFamily: "DIN-Bold",
    textTransform: "none",
    color: "#0F1120",
    backgroundColor: "#FFE443",
    "&:hover": {
        backgroundColor: "#FFEE8E",
    },
});

export default function GoBackButtonPM(props) {
    const navigate = useNavigate();

    return (
        <YellowButton
            variant="contained"
            onClick={() => {
                window.scrollTo(0, 0);
                navigate("/pmcasestudies");
                props.setCurrentPMProject("");
            }}
        >
            Back to Projects
        </YellowButton>
    );
}
