import React from "react";

export default class ImagePreloader extends React.Component {
    componentDidMount() {
        const img = new Image();
        img.src = `${process.env.PUBLIC_URL}/JeffSquare.jpg`;
    }

    render() {
        return null;
    }
}
