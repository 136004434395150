import { Outlet } from "react-router-dom";
import Navigation from "./components/Navbar";
import Footer from "./components/Footerbar";

export default function Layout(props) {
    return (
        <>
            <div>
                <Navigation props={props} />
            </div>
            <div>
                <Outlet />
            </div>
            <div>
                <Footer props={props} />
            </div>
        </>
    );
}
