import StyledIconButton from "./CustomIconButton";
import GitHubIcon from "@mui/icons-material/GitHub";

export default function ContactGitHubIcon(props) {
    return (
        <StyledIconButton
            href={props.props.contactPageData.gitHubLink}
            target="blank"
        >
            <GitHubIcon className="Contact-Icons" />
        </StyledIconButton>
    );
}
