import { useEffect } from "react";
import AboutMe from "../components/AboutMe";
import Resume from "../components/Resume";
import MobileResume from "../components/Resume-Mobile";
import ReactGA from "react-ga4";
import SEO from "../api/SEO";

export default function About(props) {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "about/" });
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <SEO
                title="About"
                description="About Jeff Pettit"
                name="Jeff Pettit"
                type="website"
            />
            <div className="Page-Background-Container">
                <div>
                    <AboutMe props={props} />
                    <div className="Horizontal-Section-Divider" />
                    {props.windowWidth >= 550 ? (
                        <Resume props={props} />
                    ) : (
                        <MobileResume props={props} />
                    )}
                </div>
            </div>
        </>
    );
}
