import { useEffect } from "react";
import ContactForm from "../components/ContactForm";
import ContactLinkedInIcon from "../icons/ContactLinkedIn";
import ContactGitHubIcon from "../icons/ContactGitHub";
import ContactGitLabIcon from "../icons/ContactGitLab";
import ContactPhoneIcon from "../icons/ContactPhone";
import ReactGA from "react-ga4";
import SEO from "../api/SEO";

export default function Contact(props) {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "contact/" });
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <SEO
                title="Contact"
                description="Contact Jeff"
                name="Jeff Pettit"
                type="website"
            />
            <div className="Page-Background-Container">
                <div className="Page-Content-Container-1">
                    <div className="H1-Header">
                        <h1>Let's work together!</h1>
                    </div>
                    <br />
                    <div className="Page-Content-Container-2">
                        <div className="Contact-Column">
                            <div className="H3-Header Contact-Header-Top-Space">
                                <h3>Contact me:</h3>
                            </div>
                            <div className="Contact-Icons-Box Contact-Top-Space">
                                {props.contactPageData.linkedInLink && (
                                    <ContactLinkedInIcon props={props} />
                                )}
                                {props.contactPageData.gitHubLink && (
                                    <ContactGitHubIcon props={props} />
                                )}
                                {props.contactPageData.gitLabLink && (
                                    <ContactGitLabIcon props={props} />
                                )}
                                {props.contactPageData.phoneNo && (
                                    <ContactPhoneIcon props={props} />
                                )}
                            </div>
                            <div className="Body-Text Contact-Top-Space">
                                <p>{props.contactPageData.pageSubHeader}</p>
                                <p>{props.contactPageData.pageBody}</p>
                            </div>
                        </div>
                        <br />
                        <div className="Contact-Column">
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
