import { useState } from "react";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

const JumpButton = styled(IconButton)({
    height: "4rem",
    width: "4rem",
    backgroundColor: "#3E404C",
    "&:hover": {
        backgroundColor: "#3E404C",
        opacity: 1,
    },
    position: "fixed",
    zIndex: 9998,
    bottom: "3.2rem",
    right: "3.2rem",
    transition: "opacity 0.2s ease-in",
});

export default function JumpToTop(props) {
    const [isHovered, setIsHovered] = useState(false);

    const jump = () => {
        window.scrollTo(0, 0);
    };

    return (
        <>
            <JumpButton
                onMouseOver={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                style={{
                    opacity: props.showJumpButton && !props.drawerOpen ? (isHovered ? 1 : 0.75) : 0,
                }}
                onClick={jump}
            >
                <ArrowUpwardIcon className="Global-Button-Icon" />
            </JumpButton>
        </>
    );
}
