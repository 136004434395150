import DownloadResumeIcon from "../icons/ResumeDownload";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";

const Job = ({ companyName, location, jobPositions }) => {
    return (
        <>
            <div className="Resume-Content-Column">
                <div className="Resume-Header-Container">
                    <div className="Resume-Header-Title-Container">
                        {companyName && (
                            <>
                                <div className="Resume-Body-Text-Bold">
                                    <p>{companyName}</p>
                                </div>
                            </>
                        )}
                        {location && (
                            <>
                                <div className="Resume-Text-Divider" />
                                <div className="Resume-Body-Text">
                                    <p>{location}</p>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                {jobPositions &&
                    jobPositions.map((position) => (
                        <JobPosition
                            key={position._key}
                            positionName={position.positionTitle}
                            positionDates={position.positionDates}
                            positionDescription={position.description}
                        />
                    ))}
            </div>
            <br />
        </>
    );
};

const JobPosition = ({ positionName, positionDates, positionDescription }) => {
    return (
        <>
            <div className="Resume-Header-Container">
                {positionName && (
                    <div className="Resume-Header-Title-Container">
                        <div className="Resume-Body-Text">
                            <p>{positionName}</p>
                        </div>
                    </div>
                )}
                {positionDates && (
                    <div className="Resume-Body-Text-Right">
                        <p>{positionDates}</p>
                    </div>
                )}
            </div>
            {positionDescription && (
                <div className="Resume-Body-Text">
                    <ul className="Resume-List-Margin">
                        {positionDescription.map((paragraph) =>
                            paragraph.listItem ? (
                                <li key={paragraph.children[0]._key}>
                                    {paragraph.children[0].text}
                                </li>
                            ) : (
                                <p key={paragraph.children[0]._key}>
                                    {paragraph.children[0].text}
                                </p>
                            )
                        )}
                    </ul>
                </div>
            )}
        </>
    );
};

const DevelopmentProject = ({
    projectName,
    projectDate,
    projectTechStack,
    projectRepositoryLinkName,
    projectRepositoryURL,
    projectDescription,
    projectResponsibilities,
}) => {
    return (
        <>
            <div className="Resume-Header-Container">
                <div className="Resume-Header-Title-Container">
                    {projectName && (
                        <div className="Resume-Body-Text-Bold">
                            <p>{projectName}</p>
                        </div>
                    )}
                    {projectRepositoryLinkName && projectRepositoryURL && (
                        <>
                            <div className="Resume-Text-Divider" />
                            <div className="Resume-Body-Text">
                                <a href={projectRepositoryURL} target="blank">
                                    {projectRepositoryLinkName}
                                </a>
                            </div>
                        </>
                    )}
                </div>
                {projectDate && (
                    <div className="Resume-Body-Text-Right">
                        <p>{projectDate}</p>
                    </div>
                )}
            </div>
            <div className="Resume-Body-Text">
                {projectTechStack && (
                    <p className="Resume-Job-Subtitle">{projectTechStack}</p>
                )}
                {projectDescription && (
                    <p className="Resume-Job-Subtitle">{projectDescription}</p>
                )}
                {projectResponsibilities && (
                    <ul className="Resume-List-Margin">
                        {projectResponsibilities.map((paragraph) =>
                            paragraph.listItem ? (
                                <li key={paragraph.children[0]._key}>
                                    {paragraph.children[0].text}
                                </li>
                            ) : (
                                <p key={paragraph.children[0]._key}>
                                    {paragraph.children[0].text}
                                </p>
                            )
                        )}
                    </ul>
                )}
            </div>

            <br />
        </>
    );
};

const Education = ({ institution, dates, degree, description }) => {
    return (
        <>
            <div className="Resume-Header-Container">
                {institution && (
                    <div className="Resume-Header-Title-Container">
                        <div className="Resume-Body-Text-Bold">
                            <p>{institution}</p>
                        </div>
                    </div>
                )}
                {dates && (
                    <div className="Resume-Body-Text-Right">
                        <p>{dates}</p>
                    </div>
                )}
            </div>
            <div className="Resume-Body-Text">
                {degree && <p>{degree}</p>}
                {description &&
                    description.map((paragraph) => (
                        <p key={paragraph.children[0]._key}>
                            {paragraph.children[0].text}
                        </p>
                    ))}
            </div>

            <br />
        </>
    );
};

const TechnicalSkills = ({ category, skills }) => {
    return (
        <>
            {category && (
                <div className="Resume-Header-Container">
                    <div className="Resume-Header-Title-Container">
                        <div className="Resume-Body-Text-Bold">
                            <p>{category}</p>
                        </div>
                    </div>
                </div>
            )}
            {skills && (
                <div className="Resume-Body-Text">
                    <p>{skills}</p>
                </div>
            )}
            <br />
        </>
    );
};

export default function Resume(props) {
    const importedJobs = props.props.resumeData.workExperience;
    const developmentProjects = props.props.resumeData.developmentExperience;
    const educationData = props.props.resumeData.education;
    const technicalSkills = props.props.resumeData.technicalSkills;
    const resumePDF = props.props.resumeData.resumePDF;

    const handleDrawerClose = () => {
        props.props.setDrawerOpen(false);
    };

    return (
        <div className="Page-Content-Container-1">
            <div className="Resume-Title-Icon-Container">
                <div className="H3-Header" style={{ marginRight: "10px" }}>
                    <h3>Current Resume</h3>
                </div>
                {resumePDF && (
                    <div>
                        <DownloadResumeIcon props={props} />
                    </div>
                )}
            </div>

            <br />

            <div className="Resume-Body-Container">
                <div className="Resume-Main-Column">
                    {importedJobs && (
                        <div className="H4-Header">
                            <h4>Professional Experience</h4>
                        </div>
                    )}

                    {importedJobs &&
                        importedJobs.map((job) => (
                            <Job
                                key={job._key}
                                companyName={job.company}
                                location={job.location}
                                jobPositions={job.positions}
                            />
                        ))}

                    {developmentProjects && (
                        <>
                            <div className="H4-Header">
                                <h4>Application Development Experience</h4>
                            </div>
                            <div className="Resume-Body-Text">
                                <p className="Resume-Job-Subtitle">
                                    Please see{" "}
                                    <NavLink
                                        className="custom-link"
                                        as={NavLink}
                                        to="/work"
                                        onClick={handleDrawerClose}
                                    >
                                        Work Section
                                    </NavLink>{" "}
                                    to view these projects and more in detail.
                                </p>
                            </div>

                            <br />
                        </>
                    )}

                    {developmentProjects &&
                        developmentProjects.map((project) => (
                            <DevelopmentProject
                                key={project._key}
                                projectName={project.projectName}
                                projectDate={project.projectDates}
                                projectTechStack={project.techStack}
                                projectRepositoryLinkName={
                                    project.projectRepository.repositoryLinkName
                                }
                                projectRepositoryURL={
                                    project.projectRepository.repositoryLinkURL
                                }
                                projectDescription={project.projectDescription}
                                projectResponsibilities={project.description}
                            />
                        ))}
                </div>

                {props.props.windowWidth < 1050 && <br />}

                <div className="Resume-Secondary-Column">
                    {educationData && (
                        <div className="H4-Header">
                            <h4>Education</h4>
                        </div>
                    )}

                    {educationData && (
                        <>
                            {educationData.map((entry) => (
                                <Education
                                    key={entry._key}
                                    institution={entry.institution}
                                    dates={entry.studyDates}
                                    degree={entry.degree}
                                    description={entry.description}
                                />
                            ))}

                            <br />
                        </>
                    )}

                    {technicalSkills && (
                        <div className="H4-Header">
                            <h4>Technical Skills</h4>
                        </div>
                    )}

                    {technicalSkills &&
                        technicalSkills.map((set) => (
                            <TechnicalSkills
                                key={set._key}
                                category={set.skillTypeTitle}
                                skills={set.skills}
                            />
                        ))}
                </div>
            </div>
        </div>
    );
}
