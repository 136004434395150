import { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { styled } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import StyledIconButton from "../icons/CustomIconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const drawerWidth = 200;

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
}));

export default function Navigation(props) {
    const drawerRef = useRef(null);

    const handleDrawerOpen = () => {
        props.props.setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        props.props.setDrawerOpen(false);
    };

    const handleClickOutsideDrawer = (e) => {
        if (drawerRef.current && !drawerRef.current.contains(e.target)) {
            handleDrawerClose();
        }
    };

    useEffect(() => {
        if (props.props.windowWidth >= 700) {
            handleDrawerClose();
        }
    }, [props.props.windowWidth]);

    useEffect(() => {
        window.addEventListener("mousedown", handleClickOutsideDrawer);
        return () => {
            window.removeEventListener("mousedown", handleClickOutsideDrawer);
        };
    }, []);

    return (
        <>
            <Navbar sticky="top" className="Navbar-Visual">
                <Navbar.Collapse>
                    <Container>
                        <Navbar.Brand
                            className="Navbar-Brand"
                            as={NavLink}
                            onClick={handleDrawerClose}
                            to="/"
                        >
                            Jeff Pettit
                        </Navbar.Brand>
                    </Container>
                </Navbar.Collapse>
                <Navbar.Collapse className="justify-content-end">
                    <Container className="Navlinks-Box">
                        {props.props.windowWidth >= 900 ? (
                            <>
                                <Nav.Link
                                    className="Navlinks"
                                    as={NavLink}
                                    to="/pmcasestudies"
                                >
                                    PM Case Studies
                                </Nav.Link>
                                <Nav.Link
                                    className="Navlinks"
                                    as={NavLink}
                                    to="/work"
                                >
                                    Engineering Projects
                                </Nav.Link>
                                <Nav.Link
                                    className="Navlinks"
                                    as={NavLink}
                                    to="/about"
                                >
                                    About
                                </Nav.Link>
                                <Nav.Link
                                    className="Navlinks"
                                    as={NavLink}
                                    to="/contact"
                                >
                                    Contact
                                </Nav.Link>
                                <Nav.Link
                                    className="Navlinks"
                                    as={NavLink}
                                    to="/blog"
                                >
                                    Blog
                                </Nav.Link>
                            </>
                        ) : (
                            <>
                                <StyledIconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={handleDrawerOpen}
                                    sx={{
                                        ...(props.props.drawerOpen && {
                                            display: "none",
                                        }),
                                    }}
                                >
                                    <MenuIcon className="Navbar-Drawer-Icon" />
                                </StyledIconButton>
                            </>
                        )}
                    </Container>
                </Navbar.Collapse>
            </Navbar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                        width: drawerWidth,
                        backgroundColor: "rgba(38, 40, 54, 0.95)",
                    },
                }}
                variant="persistent"
                anchor="right"
                open={props.props.drawerOpen}
                ref={drawerRef}
            >
                <DrawerHeader>
                    <StyledIconButton onClick={handleDrawerClose}>
                        <ChevronRightIcon className="Navbar-Drawer-Icon" />
                    </StyledIconButton>
                </DrawerHeader>
                <Divider
                    sx={{
                        backgroundColor: "#FFFFFF",
                    }}
                />
                <List>
                    <br />
                    <Nav.Link
                        className="Navlinks"
                        as={NavLink}
                        to="/pmcasestudies"
                    >
                        PM Case Studies
                    </Nav.Link>
                    <br />
                    <Nav.Link className="Navlinks" as={NavLink} to="/work">
                        Engineering Projects
                    </Nav.Link>
                    <br />
                    <Nav.Link
                        className="Navlinks"
                        as={NavLink}
                        onClick={handleDrawerClose}
                        to="/about"
                    >
                        About
                    </Nav.Link>
                    <br />
                    <Nav.Link
                        className="Navlinks"
                        as={NavLink}
                        onClick={handleDrawerClose}
                        to="/contact"
                    >
                        Contact
                    </Nav.Link>
                    <br />
                    <Nav.Link
                        className="Navlinks"
                        as={NavLink}
                        onClick={handleDrawerClose}
                        to="/blog"
                    >
                        Blog
                    </Nav.Link>
                </List>
            </Drawer>
        </>
    );
}
