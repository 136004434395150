import StyledIconButton from "./CustomIconButton";
import DownloadIcon from "@mui/icons-material/Download";

export default function DownloadResumeIcon(props) {
    const resumePDF = props.props.props.resumeData.resumePDF;

    return (
        <StyledIconButton
            href={resumePDF}
            download="Jeff-Pettit_Resume_2023.pdf"
        >
            <DownloadIcon className="Resume-Download-Icon" />
        </StyledIconButton>
    );
}
