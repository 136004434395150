export default function AboutMe(props) {
    return (
        <div className="Page-Content-Container-1">
            <div className="Page-Content-Container-2">
                {props.props.aboutPageData.avatarImageURL && (
                    <div className="Page-Content-Column-1">
                        <div className="Element-Centered">
                            <img
                                className="About-Avatar-Image"
                                alt={props.props.aboutPageData.avatarImageAlt}
                                src={props.props.aboutPageData.avatarImageURL}
                                loading="eager"
                            />
                        </div>
                    </div>
                )}
                <div className="Page-Content-Column-1">
                    {props.props.aboutPageData.aboutPageTitle && (
                        <div className="H1-Header">
                            <h1>{props.props.aboutPageData.aboutPageTitle}</h1>
                        </div>
                    )}
                    {props.props.aboutPageData.aboutPageSubtitle && (
                        <>
                            <br />
                            <div className="H3-Header">
                                <h3>
                                    {
                                        props.props.aboutPageData
                                            .aboutPageSubtitle
                                    }
                                </h3>
                            </div>
                        </>
                    )}
                    {props.props.aboutPageData.aboutBody && (
                        <>
                            <br />
                            <div className="Body-Text">
                                {props.props.aboutPageData.aboutBody.map(
                                    (paragraph, index) => (
                                        <p key={index}>
                                            {paragraph.children[0].text}
                                        </p>
                                    )
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}
