import { useEffect } from "react";
import ReactGA from "react-ga4";
import SEO from "../api/SEO";

const VimeoEmbedLandscape = ({ landscapeVideoURL, landscapeVideoTitle }) => {
    return (
        <iframe
            className="Work-Vimeo-Embed-Landscape"
            src={landscapeVideoURL}
            allow="autoplay; fullscreen; picture-in-picture"
            title={landscapeVideoTitle}
        />
    );
};

const VimeoEmbedPortrait = ({ portraitVideoURL, portraitVideoTitle }) => {
    return (
        <iframe
            className="Work-Vimeo-Embed-Portrait"
            src={portraitVideoURL}
            allow="autoplay; fullscreen; picture-in-picture"
            title={portraitVideoTitle}
        />
    );
};

const ProjectImage = ({ projectImageAlt, projectImageURL }) => {
    return (
        <img
            className="Work-Project-Image"
            alt={projectImageAlt}
            src={projectImageURL}
            loading="lazy"
        />
    );
};

const ProjectDescriptionBlock = ({ projectDescription }) => {
    const renderBlocks = (blocks) => {
        return blocks.map((block, index) => {
            if (block._type === "block" && block.children) {
                return (
                    <p key={index}>
                        {block.children.map((child, childIndex) => {
                            if (child._type === "span") {
                                return (
                                    <span key={childIndex}>{child.text}</span>
                                );
                            }
                            return null;
                        })}
                    </p>
                );
            }
            return null;
        });
    };
    return <>{renderBlocks(projectDescription)}</>;
};

const Project = ({
    projectTitle,
    projectSubtitle,
    projectRepository,
    repositoryLinkTitle,
    repositoryLinkURL,
    projectDeployment,
    deploymentLinkTitle,
    deploymentLinkURL,
    projectVisualType,
    landscapeVideoURL,
    landscapeVideoTitle,
    portraitVideoURL,
    portraitVideoTitle,
    projectImageAlt,
    projectImageURL,
    projectDescription,
    isLastProject,
}) => {
    return (
        <>
            <div className="Page-Content-Container-2">
                {projectVisualType === "portraitVideoEmbed" && (
                    <div className="Page-Content-Column-3">
                        <VimeoEmbedPortrait
                            portraitVideoURL={portraitVideoURL}
                            portraitVideoTitle={portraitVideoTitle}
                        />
                    </div>
                )}
                {projectVisualType === "landscapeVideoEmbed" && (
                    <div className="Page-Content-Column-3">
                        <VimeoEmbedLandscape
                            landscapeVideoURL={landscapeVideoURL}
                            landscapeVideoTitle={landscapeVideoTitle}
                        />
                    </div>
                )}
                {projectVisualType === "photo" && (
                    <div className="Page-Content-Column-3">
                        <ProjectImage
                            projectImageAlt={projectImageAlt}
                            projectImageURL={projectImageURL}
                        />
                    </div>
                )}
                <div className="Page-Content-Column-4">
                    {projectTitle && (
                        <div className="H3-Header">
                            <h3>{projectTitle}</h3>
                        </div>
                    )}
                    {projectSubtitle && (
                        <>
                            <br />
                            <div className="Body-Text-Bold">
                                <p>{projectSubtitle}</p>
                            </div>
                        </>
                    )}
                    <div className="Body-Text">
                        {projectDescription && (
                            <ProjectDescriptionBlock
                                projectDescription={projectDescription}
                            />
                        )}
                        {projectRepository && (
                            <>
                                <br />
                                <a href={repositoryLinkURL} target="_blank">
                                    {repositoryLinkTitle}
                                </a>
                            </>
                        )}
                        {projectDeployment &&
                            projectDeployment.deploymentLinkName &&
                            projectDeployment.deploymentLinkURL && (
                                <>
                                    <br />
                                    <a href={deploymentLinkURL} target="_blank">
                                        {deploymentLinkTitle}
                                    </a>
                                </>
                            )}
                    </div>
                </div>
            </div>
            {!isLastProject && (
                <div className="Work-Horizontal-Section-Divider" />
            )}
        </>
    );
};

export default function Work(props) {
    const pageHeader = props.workPageData.pageHeader;
    const pageSubHeader = props.workPageData.pageSubHeader;
    const projects = props.workPageData.projects;

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "work/" });
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <SEO
                title="Software Engineering Projects"
                description="Explore Jeff's recent software projects"
                name="Jeff Pettit"
                type="website"
            />
            <div className="Page-Background-Container">
                <div className="Page-Content-Container-1">
                    {pageHeader && (
                        <div className="H1-Header">
                            <h1>{pageHeader}</h1>
                        </div>
                    )}
                    {pageSubHeader && (
                        <>
                            <br />
                            <div className="H3-Header">
                                <h3>{pageSubHeader}</h3>
                            </div>
                        </>
                    )}

                    <div className="Work-Horizontal-Section-Divider" />

                    {projects &&
                        projects.map((project, index) => (
                            <div key={index}>
                                <Project
                                    projectTitle={project.projectTitle}
                                    projectSubtitle={project.projectSubtitle}
                                    projectRepository={
                                        project.projectRepository
                                    }
                                    repositoryLinkTitle={
                                        project.projectRepository
                                            .repositoryLinkName
                                    }
                                    repositoryLinkURL={
                                        project.projectRepository
                                            .repositoryLinkURL
                                    }
                                    projectDeployment={
                                        project.projectDeployment
                                    }
                                    deploymentLinkTitle={
                                        project.projectDeployment
                                            ? project.projectDeployment
                                                  .deploymentLinkName
                                            : null
                                    }
                                    deploymentLinkURL={
                                        project.projectDeployment
                                            ? project.projectDeployment
                                                  .deploymentLinkURL
                                            : null
                                    }
                                    projectVisualType={
                                        project.visual.visualType
                                    }
                                    landscapeVideoURL={
                                        project.visual.landscapeVideoUrl
                                    }
                                    landscapeVideoTitle={
                                        project.visual.landscapeVideoTitle
                                    }
                                    portraitVideoURL={
                                        project.visual.portraitVideoUrl
                                    }
                                    portraitVideoTitle={
                                        project.visual.portraitVideoTitle
                                    }
                                    projectImageAlt={
                                        project.visual.photoAltText
                                    }
                                    projectImageURL={project.visual.photoURL}
                                    projectDescription={
                                        project.projectDescription
                                    }
                                    isLastProject={
                                        index === projects.length - 1
                                    }
                                />
                            </div>
                        ))}
                </div>
            </div>
        </>
    );
}
